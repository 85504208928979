@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'components/custom-report.css';
@import 'components/dropdown.css';
@import 'components/monthly-savings.css';
@import 'components/table.css';
@import 'tailwindcss/utilities';
@import 'utilities/transitions.css';
@import 'utilities/rotate.css';

.report-date-range input::placeholder,
#report-quarter .mr-3 {
    color: #495057;
}

.cruncher-highlight {
    background: yellow;
}

.workload__echart--boundary {
    margin-top: 1em;
    max-height: 550px;
    overflow-y: auto;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome  */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
