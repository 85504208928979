.monthly-savings {
    width: 100%;
    max-width: 1500px;
}

.monthly-savings__range {
    margin-bottom: 40px;
}

.monthly-savings__values th,
.monthly-savings__values td {
    padding: 8px 0;
}

.monthly-savings__values-row:hover * {
    background: #f8f8f8;
}

.monthly-savings__range-start, .monthly-savings__range-end {
    display: flex;
    justify-content: center;
}

.monthly-savings__range-start > *, .monthly-savings__range-end > * {
    margin-left: 25px;
    margin-right: 25px;
}

.monthly-savings__values-header > *, .monthly-savings__values-row > * {
    text-align: center;
}

.monthly-savings__values-year {
    width: 55px;
}

.monthly-savings__values-month {
    width: 75px;
}

.monthly-savings__values-month > input {
    width: 100%;
    text-align: center;
}

.monthly-savings__values-sum {
    font-weight: bold;
    width: 90px;
}
