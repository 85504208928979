.dropdown {

}

.dropdown__reference {
    width: 100%;
    height: 3rem;
    border-bottom-width: 1px;
    outline: none;
}

.dropdown__reference:focus {
    outline: none;
}

.dropdown__reference-text {
    width: calc(100% - 1rem);
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.dropdown__reference-icon {
    width: 1rem;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.dropdown__popper {

}
