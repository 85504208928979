table.table {
    @apply .w-full;
}

table.table thead tr {
    @apply .border-b .border-gray-100;
}

table.table thead tr th {
    @apply .px-6 .py-7 .text-left .font-light .text-sm .text-gray-500;
}

table.table tbody tr {
    @apply .border-b .border-gray-100;
}

table.table tbody tr td {
    @apply .px-6 .py-7;
}
